import Tabs from "@sellernote/_shared/src/admin-ui/components/Tabs";
import QuotationsUser from "@sellernote/_shared/src/admin-ui/pageContainers/bid/detail/QuotationsUser";
import { GET_ADMIN_BID_DETAIL_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";

const BidDetailTabs = ({
  bidDetail,
}: {
  bidDetail: GET_ADMIN_BID_DETAIL_RES;
}) => {
  return (
    <Tabs
      defaultTabPanelValue={0}
      tabsData={[
        {
          tabLabel: "확정 견적",
          tabPanelItem: bidDetail.quotationsUser.length > 0 && (
            <QuotationsUser shipmentDetail={bidDetail} />
          ),
        },
      ]}
    />
  );
};

export default BidDetailTabs;
