import { Grid, SxProps, Theme, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { ExporterInfo as ExporterInfoType } from "@sellernote/_shared/src/types/common/common";

const columnStyle: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
};

/* TODO: 콘솔 EXW일 때 수출자 정보에 출발지가 추가되어야 함 */
function ExporterInfo({ exporterInfo }: { exporterInfo: ExporterInfoType }) {
  return (
    <Grid
      container
      display="flex"
      justifyContent="space-between"
      sx={{
        padding: "4px 8px",
        backgroundColor: grey[300],
      }}
    >
      <Grid item xs={2} sx={columnStyle}>
        <Typography variant="overline" className="sads" color={grey[700]}>
          COMPANY NAME
        </Typography>

        <Typography variant="body1" className="sads">
          {exporterInfo.companyName || "-"}
        </Typography>
      </Grid>

      <Grid item xs={2} sx={columnStyle}>
        <Typography variant="overline" className="sads" color={grey[700]}>
          NAME
        </Typography>

        <Typography variant="body1" className="sads">
          {exporterInfo.personName || "-"}
        </Typography>
      </Grid>

      <Grid item xs={2} sx={columnStyle}>
        <Typography variant="overline" className="sads" color={grey[700]}>
          EMAIL
        </Typography>

        <Typography variant="body1" className="sads">
          {exporterInfo.personEmail || "-"}
        </Typography>
      </Grid>

      <Grid item xs={2} sx={columnStyle}>
        <Typography variant="overline" className="sads" color={grey[700]}>
          TEL
        </Typography>

        <Typography variant="body1" className="sads">
          {exporterInfo.personPhone || "-"}
        </Typography>
      </Grid>

      <Grid item xs={4} sx={columnStyle}>
        <Typography variant="overline" className="sads" color={grey[700]}>
          출발지 주소
        </Typography>

        <Typography variant="body1" className="sads">
          {exporterInfo.address || "-"}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ExporterInfo;
