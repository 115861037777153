import { useParams } from "react-router-dom";
import { Box, Divider, SxProps, Theme, Typography } from "@mui/material";

import BackTop from "@sellernote/_shared/src/admin-ui/components/BackTop";
import CargoInfo from "@sellernote/_shared/src/admin-ui/components/CargoInfo";
import CommentList from "@sellernote/_shared/src/admin-ui/components/CommentList";
import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import BasicDesc from "@sellernote/_shared/src/admin-ui/pageContainers/bid/detail/BasicDesc";
import CheckDesc from "@sellernote/_shared/src/admin-ui/pageContainers/bid/detail/CheckDesc";
import ExporterDesc from "@sellernote/_shared/src/admin-ui/pageContainers/bid/detail/ExporterDesc";
import TransportDesc from "@sellernote/_shared/src/admin-ui/pageContainers/bid/detail/TransportDesc";
import UserDesc from "@sellernote/_shared/src/admin-ui/pageContainers/bid/detail/UserDesc";
import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import useShipmentCommentList from "@sellernote/_shared/src/hooks/admin/useShipmentCommentList";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import CUSTOMS_ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/CUSTOMS_ADMIN_BID_QUERY";

import BidDetailTabs from "./BidDetailTabs";

const boxLayoutStyle: SxProps<Theme> = {
  p: "16px",
  borderRadius: "4px",
  background: "#fff",
  border: "1px solid #e0e0e0",
};

//TODO: 쉽다 어드민과 공용 컴포넌트로 변경
const BidDetail = () => {
  const { id }: { id: string } = useParams();

  const { data: bidDetail } = CUSTOMS_ADMIN_BID_QUERY.useGetCustomsBidDetail({
    bidId: Number(id),
  });

  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  const { data: zoneList } = ADMIN_COMMON_QUERY.useGetZoneList();

  const { data: countryList } = ADMIN_COMMON_QUERY.useGetCountryList();

  const { data: exchangeRateList } = ADMIN_COMMON_QUERY.useGetExchangeRateList({
    all: true,
    countryTarget: bidDetail?.locale,
  });

  const {
    handleCommentInputChange,
    handleCommentUpdate,
    comment,
    shipmentCommentList,
    handleCommentEdit,
    handleCommentDelete,
    ResponseHandler: ResponseHandlerOfShipmentCommentList,
  } = useShipmentCommentList({ shipmentId: Number(id) });

  if (
    !bidDetail ||
    !portList ||
    !zoneList ||
    !countryList ||
    !exchangeRateList
  ) {
    return (
      <Layout breadcrumbs={["쉽다 의뢰"]} title={"의뢰 상세 정보"}>
        <Loading active={true} />
      </Layout>
    );
  }

  return (
    <Layout breadcrumbs={["쉽다 의뢰"]} title={"의뢰 상세 정보"}>
      <BackTop />

      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "20px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "12px",
            marginTop: "16px",
          }}
        >
          <BasicDesc bidDetail={bidDetail} />

          <Box sx={boxLayoutStyle}>
            <Typography variant="h6" className="sads">
              사용자 정보
            </Typography>

            <UserDesc bidDetail={bidDetail} />
          </Box>
        </Box>

        <Box sx={boxLayoutStyle}>
          <Typography variant="h6" className="sads">
            구간 정보
          </Typography>

          <TransportDesc bidDetail={bidDetail} />

          <Divider sx={{ my: "24px" }} />

          <Typography variant="h6" className="sads">
            확인 사항
          </Typography>

          <CheckDesc bidDetail={bidDetail} />
        </Box>

        {bidDetail.serviceType !== "consolidation" && (
          <Box sx={boxLayoutStyle}>
            <ExporterDesc bidDetail={bidDetail}></ExporterDesc>
          </Box>
        )}

        <Box sx={boxLayoutStyle} position="relative">
          <Typography variant="h6">
            {bidDetail.serviceType === "consolidation" && "수출자 및 "}화물 정보
          </Typography>

          <CargoInfo bidDetail={bidDetail} />
        </Box>

        <Box sx={{ ...boxLayoutStyle, display: "flex" }}>
          <CommentList
            commentDataList={bidDetail.management.comments}
            commentPageType={"AutoShipment"}
          />

          <CommentList
            comment={comment}
            onCommentInputChange={handleCommentInputChange}
            onCommentUpdate={handleCommentUpdate}
            commentDataList={shipmentCommentList}
            commentPageType="ManualShipment"
            onCommentEdit={handleCommentEdit}
            onCommentDelete={handleCommentDelete}
          />
        </Box>

        <Box sx={boxLayoutStyle}>
          <BidDetailTabs bidDetail={bidDetail} />
        </Box>
      </Box>

      {ResponseHandlerOfShipmentCommentList}
    </Layout>
  );
};

export default BidDetail;
