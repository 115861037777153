import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useAtomValue } from "jotai";

import Navigation from "@sellernote/_shared/src/admin-ui/components/Navigation";
import withRequireAuth from "@sellernote/_shared/src/hocs/withRequireAuth";
import { FORWARDING_ADMIN_AUTH_ATOMS } from "@sellernote/_shared/src/jotaiStates/auth";
import ErrorBoundary from "@sellernote/_sds-v1/src/components/error/ErrorBoundary";
import PageNotFoundForCRA from "@sellernote/_sds-v1/src/components/error/PageNotFoundForCRA";

import Home from "pages";
import Bid from "pages/bid";
import BidDetail from "pages/bid/detail";
import ChangePassword from "pages/change-password";
import Login from "pages/login";
import TrelloListByServiceType from "pages/trello/:serviceType";

function AppRouter({ children }: { children: React.ReactNode }) {
  const loggedIn = useAtomValue(
    FORWARDING_ADMIN_AUTH_ATOMS.PARTNER_ADMIN_LOGGED_IN
  );

  return (
    <Router>
      <ErrorBoundary>
        <div
          style={{
            display: "flex",
            overflow: "hidden",
            width: "100%",
            height: "100%",
          }}
        >
          {loggedIn && <Navigation />}

          <div style={{ overflow: "hidden", flex: 1 }}>
            <Switch>
              <Route path="/" component={withRequireAuth(Home)} exact />

              <Route path="/login" component={Login} exact />

              <Route
                path="/change-password"
                component={withRequireAuth(ChangePassword)}
                exact
              />

              <Route path="/bid" component={withRequireAuth(Bid)} exact />

              <Route
                path="/bid/detail/:id"
                component={withRequireAuth(BidDetail)}
                exact
              />

              <Route
                path="/trello/:serviceType"
                component={withRequireAuth(TrelloListByServiceType)}
                exact
              />

              <Route path="*" component={PageNotFoundForCRA} />
            </Switch>

            {children}
          </div>
        </div>
      </ErrorBoundary>
    </Router>
  );
}

export default AppRouter;
